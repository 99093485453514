

















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import WorkflowService from '@/services/WorkflowService';
import config from '@/config';
import PolicyService from '@/services/PolicyService';

@Component
export default class PremiumFunding extends Vue {
  @Action('app/setValue') private setValue: any;
  @Getter('proposal/getInvoicesPriceToPay') private getInvoicesPriceToPay: any;
  @Getter('app/getPaymentProviders') private getPaymentProviders: any;
  @State private proposal: any;
  @State private app: any;
  private priceToPay: any;
  private quoteResult: any = {};
  private showIFrameWindow = false;
  private showPayButton = false;
  public openIFrame(): void {
    this.showIFrameWindow = !this.showIFrameWindow;
    this.showPayButton = false;
  }
  public getAttvestIframeURL(): string {
    return config.urls.attvestIFrameURL + '/direct-payment/' + this.quoteResult.session + '/' +  this.quoteResult.quoteNumber + '?enableDisclosures=true';
  }
  public getMonthlyInstallment() {
    if (this.quoteResult?.totalRepayable) {
      return  (this.quoteResult?.totalRepayable / this.quoteResult?.numberOfInstalments)?.toFixed(2);
    }
    return '';
  }

  private async created() {
    this.priceToPay =  this.getInvoicesPriceToPay().toFixed(2);
    const res = await WorkflowService.runWorkflowSync(
      'create-premium-funding-quote',
     this.proposal.invoiceId,
      ['quoteResult']
    );
    this.quoteResult = res.data?.data?.quoteResult;
    this.showPayButton = true;
  }

  private async mounted() {
    this.setValue({ code: 'isPayButtonVisible', value: false });
    window.addEventListener('message', this.handleIframeMessage);
  }

  private beforeDestroy() {
    this.setValue({ code: 'isPayButtonVisible', value: true });
    window.removeEventListener('message', this.handleIframeMessage);
  }

  private proceedToConfirmation(type: string = '', completed: boolean = true) {
    const query: any = Object.assign(
      this.$route.query,
      {
        language: this.app.language,
        type,
        completed
      }
    );
    this.$router.push({ name: 'confirmation', query }, undefined, (error) => {
      this.$dialog.open({ type: 'redirection-issue-payment', info: 'Payment: redirection to confirmation failed', level: 'error' });
    });
  }

  private iframeLoaded() {
    console.log('Iframe loaded');
    if (!this.quoteResult?.quoteNumber) {
      this.showIFrameWindow = false;
      this.showPayButton = true;
      this.$dialog.open({
        type: 'premium-funding-payment-declined',
        info: 'Error in Quote creation'
      });
    }
  }
  private formatDecimal(value: any): string {
    return value ? Number(value).toFixed(2) : '';
  }
  private getPremiumFundingProvider() {
    const paymentProviders = this.getPaymentProviders();
    return paymentProviders.find(((paymentProvider) => paymentProvider.frontEndConfigKey === 'premium-funding'));
  }

  private async handleIframeMessage(event: MessageEvent) {
    // Ensure the message is coming from the iframe's origin
    if (event.origin !== config.urls.attvestIFrameURL) { return; }
    try {
      const data = JSON.parse(event.data);
      const success = data.result === 'sucess';
      await PolicyService.postPolicyInvoiceSettle(
        this.proposal.invoiceId,
        this.getPremiumFundingProvider().id,
        'premium-funding',
        '' ,
        {
          loanNumber: data.loanNumber,
          success,
          error: data.message ?? null
        }
      );
      if (data.result === 'sucess') {
        this.proceedToConfirmation('', true);
      } else {
        this.showIFrameWindow = false;
        this.showPayButton = true;
        this.$dialog.open({
          type: 'premium-funding-payment-declined',
          info: 'Payment declined by the Funder'
        });

      }
    } catch (error) {
      this.$dialog.open({
        type: 'technical-issue'
      });
    }

  }
}
