import {APIResource, APIError} from '@/utils/APIResources';
import {AxiosError} from 'axios';
import {
  V3PolicyApiAxiosParamCreator,
  ConvertQuoteModel,
  PolicyNoteCreateModel,
  PolicyPartyMappingConfigModel, PolicyPartyModel
} from 'shift-policy-service-api-client';

const service = V3PolicyApiAxiosParamCreator();

export default class {
  public static async getPolicyStatus(id: string) {
    const req = await service.getPolicy(id);
    return APIResource.get(req.url, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async getGuestPolicyPartyForUserId(userId: string): Promise<PolicyPartyModel | null> {
    const req = await service.getPolicyPartiesByUserId(userId, 0, 1, '+sfCreated');
    return APIResource.get(req.url, req.options)
        .then((response) => {
          return response.data.data.length > 0 ? response.data.data[0] : null;
        })
        .catch((error: AxiosError) => {
          return Promise.reject(new APIError(error));
        });
  }

  public static async getPolicyPartyMappingConfig(accountId: number): Promise<PolicyPartyMappingConfigModel> {
    const req = await service.getPolicyPartyMappingConfig(accountId);
    return APIResource.get(req.url, req.options)
        .then((response) => {
          return response.data.data;
        })
        .catch((error: AxiosError) => {
          return Promise.reject(new APIError(error));
        });
  }

  public static async getInvoiceStatus(id: string) {
    const req = await service.getInvoiceLinesByInvoiceId(id);
    return APIResource.get(req.url, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async getBundleStatus(id: string) {
    const req = await service.getPolicyBundle(id);
    return APIResource.get(req.url, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async getPolicyByBundle(id: string) {
    const req = await service.getPoliciesByPolicyBundleId(id);
    return APIResource.get(req.url, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async getSettlementStatus(invoiceId: string) {
    const req = await service.invoiceSettlementStatus(invoiceId);
    return APIResource.get(req.url, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async convertPolicy(params: ConvertQuoteModel) {
    const req = await service.convertQuotes(params);
    return APIResource.post(req.url, req.options.data, req.options)
    .catch((error: AxiosError) => {
      return Promise.reject(new APIError(error));
    });
  }

  public static async postPolicynote(id: string) {
    const params: PolicyNoteCreateModel = {
      createdBy: 'Sales Tool Payment Page',
      subject: 'System message: lead has requested assistance with payment',
      policyId: id,
      note: '',
      sfAccountId: -1
    };
    const req = await service.createPolicyNote(params);
    return APIResource.post(req.url, req.options.data, req.options)
        .catch(() => {
          return Promise.resolve();
        });
  }

  public static async postPolicyInvoiceSettle(invoiceId: string, paymentProviderId: string, token: string | null, transactionRef?: string, requestBody?: any ) {
    const options: any = {};
    if (token) {
      options.paymentRequest = JSON.stringify({dataKey: token});
    }

    if (transactionRef) {
      options.paymentRequest = `{"transactionReference": "${transactionRef}"}`;
    }
    if (requestBody) {
      options.paymentRequest = JSON.stringify(requestBody);
    }
    const req = await service.invoiceSettlementSync(invoiceId, { ...options, paymentProviderId});
    return APIResource.post(req.url, req.options.data, req.options)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static async namedPostPolicyInvoiceSettle(invoiceId: string, paymentProvider: string, token: string) {
    const paymentRequest = JSON.stringify({dataKey: token});
    const req = await service.namedInvoiceSettlementAsync(paymentProvider, invoiceId, token);
    return APIResource.post(req.url, req.options.data, req.options)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static async getPaymentProviders() {
    const req = await service.getAllPaymentProviders();
    return APIResource.get(req.url, req.options)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

  public static async getPaymentProviderConfigFrontend(providerName: string, invoiceId: string, paymentProviderConfigRequest: any) {
    const req = await service.getPaymentProviderConfigFrontend(providerName, invoiceId, paymentProviderConfigRequest);
    return APIResource.post(req.url, req.options.data, req.options)
      .catch((error: AxiosError) => {
        return Promise.reject(new APIError(error));
      });
  }

}
