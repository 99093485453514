import {MutationTree} from 'vuex';
import {
  RESET,
  SET_LANGUAGE,
  SET_DETECTED_COUNTRY,
  SET_TARGET_SEGMENT,
  SET_CLIENT_ID,
  SET_PROFILE,
  SET_LINE,
  SET_AREA_OF_SERVICE,
  SET_PRODUCTS,
  SET_PAYMENT_PROVIDERS,
  SET_STEPPER_ACTIVE_STEP,
  SET_STEPPER_TOTAL_STEPS,
  SET_FOOTER_VISIBILITY,
  SET_POLICY_IDS,
  SET_BUNDLE_ID,
  SET_ERROR,
  SET_VALUE,
  SET_LOADING_TEXT,
  SET_IS_CONTINUATION,
  SET_RENEWAL_RESPONSE,
  SET_PROPOSAL_NUMBER,
  SET_PAY_BUTTON_VISIBLE,
  SET_AFFILIATE_CODE,
  SET_CONTENT_READY,
  SET_PE_PROGRESS_STATUS,
  SET_SELECTED_PAYMENT_PROVIDER
} from './mutationTypes';
import {IApp} from '@/interfaces';
import getInitialState from './initialState';

const mutations: MutationTree<IApp> = {
  [SET_LANGUAGE](state: IApp, payload: string) {
    state.language = payload;
  },

  [SET_PROFILE](state: IApp, payload: string) {
    state.profile = payload;
  },

  [SET_AFFILIATE_CODE](state: IApp, payload: string) {
    state.affiliateCode = payload;
  },

  [SET_LINE](state: IApp, payload: string) {
    state.line = payload;
  },

  [SET_AREA_OF_SERVICE](state: IApp, payload: string) {
    state.areaOfService = payload;
  },

  [SET_PRODUCTS](state: IApp, payload: string[]) {
    state.products = payload;
  },

  [SET_PAYMENT_PROVIDERS](state: IApp, payload: any[]) {
    state.paymentProviders = payload;
  },

  [SET_SELECTED_PAYMENT_PROVIDER](state: IApp, payload: string) {
    state.selectedPaymentProvider = payload;
  },

  [SET_TARGET_SEGMENT](state: IApp, payload: any) {
    state.targetSegment = payload.targetSegment;
    state.languages = payload.languages;
    state.journeyLanguages = payload.journeyLanguages;
    state.showRenewLink = payload.showRenewLink;
    state.quoteOnly = payload.quoteOnly;
    state.showSaveProposalButton = payload.showSaveProposalButton;
    state.showSaveProposalButtonOnValidPrice = payload.showSaveProposalButtonOnValidPrice;
    state.showSendAdditionalInvoiceEmailSegment = payload.showSendAdditionalInvoiceEmailSegment;
    state.showDatesSection = payload.showDatesSection;
    state.renewalSkipCatalogue = payload.renewalSkipCatalogue;
    state.showRequestButton = payload.showRequestButton;
    state.homeUrl = payload.homeUrl;
    state.catalogueUrl = payload.catalogueUrl;
    state.adviserPhone = payload.adviserPhone;
    state.adviserEmail = payload.adviserEmail;
    state.helpAddress = payload.helpAddress;
    state.helpPhone = payload.helpPhone;
    state.helpFax = payload.helpFax;
    state.helpEmail = payload.helpEmail;
    state.downloadAddress = payload.downloadAddress;
    state.downloadFax = payload.downloadFax;
    state.targetShiftClientId = payload.targetShiftClientId;
    state.config = payload.config;
    state.helpAddresses = payload.helpAddresses;
    state.copyCoverageDate = payload.copyCoverageDate || false;
    state.contactInfoFacts = payload.contactInfoFacts ? payload.contactInfoFacts : getInitialState().contactInfoFacts;
  },

  [SET_CLIENT_ID](state: IApp, payload: string) {
    state.targetShiftClientId = payload;
  },

  [SET_STEPPER_ACTIVE_STEP](state: IApp, payload: number) {
    state.activeStep = payload;
    state.furthestStep = Math.max(state.furthestStep || 0, payload);
  },

  [SET_STEPPER_TOTAL_STEPS](state: IApp, payload: number) {
    state.totalSteps = payload;
  },

  [SET_DETECTED_COUNTRY](state: IApp, payload: string) {
    state.detectedCountry = payload;
  },

  [SET_FOOTER_VISIBILITY](state: IApp, payload: boolean) {
    state.isFooterVisible = payload;
  },

  [SET_POLICY_IDS](state: IApp, payload: string[]) {
    state.policyIds = payload;
  },

  [SET_BUNDLE_ID](state: IApp, payload: string) {
    state.bundleId = payload;
  },

  [SET_ERROR](state: IApp, payload: string) {
    state.error = payload;
  },

  [SET_VALUE](state: IApp, payload: any) {
    state[payload.code] = payload.value;
  },

  [SET_LOADING_TEXT](state: IApp, payload: string) {
    state.loadingText = payload;
  },

  [SET_IS_CONTINUATION](state: IApp, payload: boolean) {
    state.isContinuation = payload;
  },

  [RESET](state: IApp) {
    Object.assign(state, getInitialState());
  },

  [SET_RENEWAL_RESPONSE](state: IApp, payload: any) {
    state.renewalResponse = payload;
  },

  [SET_PROPOSAL_NUMBER](state: IApp, payload: any) {
    state.proposalId = payload.proposalId;
  },

  [SET_PAY_BUTTON_VISIBLE](state: IApp, payload: boolean) {
    state.isPayButtonVisible = payload;
  },

  [SET_CONTENT_READY](state: IApp, payload: boolean) {
    state.isContentReady = payload;
  },

  [SET_PE_PROGRESS_STATUS](state: IApp, payload: boolean) {
    state.isPeInProgress = payload;
  },
};

export default mutations;
