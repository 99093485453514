












































































































import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import _ from 'lodash';
import Util from '@/utils/Util';

import Footer from '@/components/layouts/Footer.vue';
import RedirectSection from '@/components/layouts/RedirectSection.vue';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';
import HeroSection from '@/components/layouts/HeroSection.vue';

import {SalesforceService} from '@/services/SalesforceService';

@Component({
  name: 'RenewalRequest',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    Footer,
    FactPhoneNumber,
    RedirectSection,
    HeroSection
  }
})

export default class RenewalRequest extends Vue {
  @State private cms: any;
  @State private app: any;
  @Getter('cms/getMessageByCode') private getMessageByCode: any;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;

  private name: string = '';
  private email: string = '';
  private phone: string = '';

  private sending: boolean = false;
  private success: boolean = false;

  get hero() {
    return _.get(this.cms, 'theme.hero');
  }

  get messageSent() {
    return '/coverhub-portal/images/message-sent.svg';
  }

  private onPhoneInput(data) {
    this.phone = data.number;
  }

  private goHome() {
    this.$router.push({name: 'landing', params: {skipConfirm: 'true'}});
  }

  private sendMessage() {
    this.$validator.validate().then((res) => {
      if (res) {
        this.sending = true;
        this.sendToCRM().then(() => {
          this.success = true;
          this.sending = false;
          this.setPardotAvailability(true);
        }).catch((err) => {
          this.sending = false;
          Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
            event_params : {
              error_type: 'technical-issue',
              error_message: err.message
            }
          });
          this.$dialog.open({ type: 'technical-issue', info: 'Renewal - sendToPardot' });
          this.setPardotAvailability(false);
        });
      }
    });
  }

  private async sendToCRM() {
    const pardotObj: any = {
      email: this.email,
      Name: this.name,
      Phone: this.phone,
      description: '',
      subject: 'System message: lead has requested to be sent their renewal link',
      Message: 'System message: lead has requested to be sent their renewal link',
      Language: this.app.language,
      Segment: sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : ''),
      Source_URL: window.location.href,
      application_status: this.app.activeStep
    };
    const config = _.get(this.app, 'config.salesforceCase', {});
    if (config) {
      pardotObj[config.segment] = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : ''),
      pardotObj[config.language] = this.app.language;
      pardotObj[config.sourceUrl] = window.location.href.replace(/\?.*/, '');
      pardotObj[config.applicationStatus] = this.app.activeStep;
    }
    return _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_RENEWAL, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(pardotObj);
  }
}
