import { render, staticRenderFns } from "./PremiumFunding.vue?vue&type=template&id=b6c7a24a&scoped=true&"
import script from "./PremiumFunding.vue?vue&type=script&lang=ts&"
export * from "./PremiumFunding.vue?vue&type=script&lang=ts&"
import style0 from "./PremiumFunding.vue?vue&type=style&index=0&id=b6c7a24a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c7a24a",
  null
  
)

export default component.exports