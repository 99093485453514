import Vue from 'vue';
import VeeValidate from 'vee-validate';
import _ from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import {i18n} from '@/plugins/intl';

const dict = {
  en: {
    messages: {
      required: (field: any) => 'validations.required',
      phone: (field: any) => 'validations.phone',
      file: (field: any, allowedTypes) => i18n.t('validations.file', {value : allowedTypes.join(',')}),
      min_value: (field: any, value: any) => i18n.t('validations.min_value', {value : value[0]}),
      max_value: (field: any, value: any) => i18n.t('validations.max_value', {value : value[0]}),
      email: (field: any, value: any) => i18n.t('validations.email'),
      declaration: (field: any, value: any) => i18n.t('validations.declaration'),
      must_agree: (field: any, value: any) => i18n.t('validations.must_agree'),
      text_integer: (field: any) => i18n.t('validations.text_integer')
    }
  }
};

VeeValidate.Validator.localize(dict);

VeeValidate.Validator.extend('min_value', (value: any, minValue) => {
  return Number(value.replace(/[^\d.-]/g, '')) >= Number(minValue![0]);
});

VeeValidate.Validator.extend('file', (value: any, allowedTypes: any) => {
  const fileName = value[0]?.name;

  if (!fileName?.includes('.')) {
    return false;
  }
  const extension = fileName ? fileName.split('.').pop() : null;
  if (!extension) {
    return false;
  }

  return allowedTypes.includes(extension);
}, {
  computesRequired: true,
  hasTarget: true
});

VeeValidate.Validator.extend('max_value', (value: any, maxValue) => {
  return Number(value.replace(/[^\d.-]/g, '')) <= Number(maxValue![0]);
});

VeeValidate.Validator.extend('phone', (value: any, other) => {
  if (value) {
    let phoneNumber: any = null;
    try {
      phoneNumber = (parsePhoneNumberFromString(value, other && other[0]));
    } catch (e) {
      console.error('Phone: parse failed');
      return false;
    }
    if (phoneNumber && phoneNumber.isValid() && (!other || phoneNumber.country === other[0])) {
      return phoneNumber.isValid();
    } else {
      return false;
    }
  } else {
    return false;
  }
}, {
  computesRequired: true,
  hasTarget: true
});

VeeValidate.Validator.extend('must_agree', (value: any) => {
  return value === true || value === 'true';
}, {
  computesRequired: true
});

VeeValidate.Validator.extend('declaration', (value: any) => {
  return value === true || value === 'true';
}, {
  computesRequired: true
});

VeeValidate.Validator.extend('text_integer', (value: any) => {
  return !!value.match(/^\d+$/);
});

Vue.use(VeeValidate, {
  inject: false,
  errorBagName: 'xxErrors',
  fieldsBagName: 'xxFields',
  events: 'changed'
});

Vue.prototype.$hasError = function(field: string | string[]) {
  if (_.isString(field)) {
    field = [field];
  }
  return this.xxErrors.items.some((error: any) => _.some(field, (f) => f.includes(error.field)));
};

Vue.prototype.$errorMsg = function(field: string | string[]) {
  if (_.isString(field)) {
    field = [field];
  }
  const errors = this.xxErrors.items.filter((err: any) => _.some(field, (f) => f.includes(err.field.split('@').pop())));
  const msgs = _.uniq(_.map(errors, (err) => this.$t(err.msg) || err.msg));
  let error = '';
  _.forEach(msgs, (msg, index) => {
    error += (index > 0 ? '\n' : '') + msg;
  });
  return error;
};

Vue.prototype.$addError = function(field: string, msg: string) {
  if (this.xxErrors) {
    this.xxErrors.add({ field, msg });
  }
};

Vue.prototype.$resetErrors = function() {
  if (this.xxErrors.any()) {
    this.xxErrors.clear();
  }
};

Vue.prototype.$resetFactErrors = function(field: string, dontRemove: boolean = false) {
  if (this.xxErrors.any() && !dontRemove) {
    this.xxErrors.remove(field);
  }
};
