const ODLog = (payload: any) => {
	(window as any).dataLayer = (window as any).dataLayer || [];
	(window as any).dataLayer.push(payload);
};

const ODEcommerceLog = (event, journey, sector, ecommerce: any) => {
	(window as any).dataLayer = (window as any).dataLayer || [];
 (window as any).dataLayer.push({ ecommerce: null});
	(window as any).dataLayer.push({event, journey, sector, ecommerce});
};

export default class GTMUtil {
    public static logGTMEvent(self, action, category, event, value) {
        if (!self.$gtm || !self.$gtm.trackEvent) {
            return;
        }
        ODLog({
            event, ...value
        });
        self.$gtm.trackEvent({
            event,
            category,
            action,
            value,
          });
    }

   public static logGTMEcommerceEvent(self, event, journey, sector, ecommerce) {
        if (!self.$gtm || !self.$gtm.trackEvent) {
            return;
        }
        ODEcommerceLog(event, journey, sector, ecommerce);
        self.$gtm.trackEvent({
            event, journey, sector, ecommerce
        });
    }
}
