











































import {Component, Vue} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import _ from 'lodash';
import EventBus from '@/common/EventBus';
import SentryService from '@/services/SentryService';

@Component({
  name: 'Dialog',
})
export default class Dialog extends Vue {
  @State private app: any;
  @State private cms: any;
  @Action('app/reset') private reset: any;
  @Action('app/setLoadingText') private setLoadingText: any;

  private dialogData = {};
  private dialogVisible: boolean = false;
  private imageVisible: boolean = false;

  get isReady() {
    return this.dialogVisible && this.imageVisible;
  }

  get dialogTypes() {
    return {
      'technical-issue': {
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('technical.message') || 'We are currently facing technical difficulties and are unable to process your request. Please try again later',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
            }
          }
        ]
      },
      'payment-cancelled': {
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('proposal.payment.cancelled') || 'Payment cancelled',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
            }
          }
        ]
      },
      'payment-declined': {
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('proposal.payment.error.gatewayIssue') || 'Payment declined',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
            }
          }
        ]
      },
      'price-change': {
        icon: _.get(this.cms, 'theme.information'),
        text: this.$t('price.change') || 'Please note your estimated amount payable has been updated based on the latest information provided. <br>Estimated premium may not be provided if some of your answers require further review.',
        buttons: [
          {
            type: 'main',
            text: this.$t('price.button') || 'Ok',
            onClick: () => {
              this.dialogVisible = false;
            }
          }
        ]
      },
      'cookies-disabled': {
        persistent: true,
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('cookie.message') || 'Our application need cookies enabled to work properly.',
        buttons: [
        ]
      },
      'redirection-issue-summary': {
        persistent: true,
        icon: _.get(this.cms, 'theme.adviser'),
        text: this.$t('product.summary.error.redirection') || 'New version of website available',
        buttons: [{
          type: 'main',
          text: this.$t('button.ok') || 'Ok',
          onClick: () => {
            const query = {};
            const targetSegment = this.app.targetSegment;
            this.reset();
            this.$router.replace({name: 'landing', params: {targetSegment}, query});
            this.reloadPage();
          }
        }]
      },
      'redirection-issue-payment': {
        persistent: true,
        icon: _.get(this.cms, 'theme.approved'),
        text: this.$t('proposal.payment.error.redirection') || 'New version of website available',
        buttons: [{
          type: 'main',
          text: this.$t('button.ok') || 'Ok',
          onClick: () => {
            const query = {};
            const targetSegment = this.app.targetSegment;
            this.reset();
            this.$router.replace({name: 'landing', params: {targetSegment}, query});
            this.reloadPage();
          }
        }]
      },
      'redirection-issue': {
        persistent: true,
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('newVersionAvailable') || 'New version of website available',
        buttons: [{
          type: 'main',
          text: this.$t('button.ok') || 'Ok',
          onClick: () => {
            const query = {};
            const targetSegment = this.app.targetSegment;
            this.reset();
            this.$router.replace({name: 'landing', params: {targetSegment}, query});
            this.reloadPage();
          }
        }]
      },
      'saved-proposal': {
        icon: _.get(this.cms, 'theme.adviser'),
        text: this.$t('proposal.saved') || 'Success! An email with a link to your saved proposal has been sent',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
            }
          }
        ]
      },
      'proposal-expired': {
        icon: _.get(this.cms, 'theme.adviser'),
        text: this.$t('proposal.expired') || 'Sorry, your proposal has expired and is no longer valid.',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              const query = {};
              const targetSegment = this.app.targetSegment;
              this.reset(true);
              this.$router.replace({name: 'landing', params: {targetSegment}, query});
              this.reloadPage();
            }
          }
        ]
      },
      'past-coverage-start-date': {
        icon: _.get(this.cms, 'theme.adviser'),
        text: this.$t('proposal.pastCoverageStartDate') || 'Please select a new coverage start date',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || 'Ok',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
              EventBus.$emit(`savedProposal-expired-ok`);
            }
          }
        ]
      },
      'premium-funding-payment-declined': {
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: 'We are currently facing issue with funder and are unable to process your request. Please select different payment method to proceed.',
        buttons: [
          {
            type: 'main',
            text: this.$t('technical.button') || '"The payment was declined by the funder. Please select different payment method to proceed.',
            onClick: () => {
              this.imageVisible = false;
              this.dialogVisible = false;
            }
          }
        ]
      },
    };
  }

  private imageReady() {
    this.imageVisible = true;
  }

  private reloadPage() {
    this.imageVisible = false;
    this.dialogVisible = false;
    if ((this.app.isRenewal || this.app.isContinuation) && this.app.originUrl) {
      window.location.href = this.app.originUrl;
    } else {
      window.location.reload();
    }
  }

  private created() {
    EventBus.$on('dialog-open', this.openDialog);
    EventBus.$on('dialog-close', this.closeDialog);
    EventBus.$on('api-error', this.technicalIssueHandler);
  }

  private getAction(button) {
    if (_.get(this.dialogData, 'closeOnClick') === false) {
      if (_.isFunction(button.onClick)) {
        button.onClick();
      }
    } else {
      if (_.isFunction(button.onClick)) {
        button.onClick();
      }
      this.closeDialog();
    }
  }

  private technicalIssueHandler(url) {
    if (!this.shouldSkipDialog(url)) {
      this.openDialog({type: 'technical-issue', info: 'technical-issue', data: url, level: 'error' });
    }
  }

  private shouldSkipDialog(url) {
    return  _.invoke(url, 'includes', ['pay']) ||
      _.invoke(url, 'includes', ['convertquotes']);
  }

  private openDialog(data: any) {
    if (_.get(data, 'info')) {
      let level = 'info';
      if (data.level) {
        level = data.level;
      }
      if (data.data) {
        SentryService.logMessage({ message: data.info, data: data.data, level });
      } else {
        SentryService.logMessage({ message: `INFO: ${data.info}`, level });
      }
    }
    if (this.dialogVisible) {
      return;
    }
    if (_.get(data, 'type')) {
      this.dialogData = this.dialogTypes[data.type] || {};
    } else {
      this.dialogData = data;
    }
    this.dialogVisible = true;
    // clear loading message if any
    this.setLoadingText(null);
  }

  private closeDialog() {
    setTimeout(() => {
      this.dialogData = {};
    }, 300);
    this.imageVisible = false;
    this.dialogVisible = false;
  }


  private clickOutside() {
    if (this.dialogData && (this.dialogData as any).persistent) {
      return;
    } else {
      this.closeDialog();
    }
  }
}

